import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import 'moment/locale/pt-br';
import api from '../../../services/api';

import {
  Container, Banner, Content, Author, ShareSection,
} from './styles';

import Header from '../../../components/Header';
import Contato from '../../../components/Contato';
import Footer from '../../../components/Footer';
import Loading from '../../../components/Loading';

import FacebookIcon from '../../../assets/images/facebook-logo.svg';
import TwitterIcon from '../../../assets/images/twitter-logo.svg';
import LinkedinIcon from '../../../assets/images/linkedin-logo.svg';
import EmailIcon from '../../../assets/images/email-logo.svg';
import ArrowRight from '../../../assets/images/arrow-point-to-right.svg';

class Post extends Component {
  state = {
    ids: null,
    post: null,
    tags: null,
    author: null,
    loading: false,
  };

  componentDidMount() {
    // Definindo locale do moment
    Moment.locale('pt-br');
    document.getElementsByTagName('body')[0].style.overflow = 'unset';

    this.setState({ loading: true });

    // Requisitar post
    this.getPost().then(() => {
      this.setState({ loading: false });
      document.title = `${this.state.post.title.rendered} - Construtora Miquelão`;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.setState({
        post: null,
        tags: null,
        author: null,
        loading: true,
      });
      this.getPost().then(() => {
        this.setState({ loading: false });
        document.title = `${this.state.post.title.rendered} - Construtora Miquelão`;
      });
    }
  }

  getPost = async () => {
    const { match } = this.props;

    const posts = await api.get('/posts?_embed');

    const postIds = posts.data.map(post => post.slug);
    this.setState({ ids: postIds });

    const post = await api.get(`/posts?slug=${match.params.slug}&_embed`);
    this.setState({ post: post.data[0], author: post.data[0]._embedded.author[0] });

    if (post) {
      const tags = await api.get(`tags?post=${post.data.id}`);
      this.setState({ tags: tags.data });
    }
  };

  prevPost = (id) => {
    const { ids } = this.state;

    const position = ids.indexOf(id);

    if (position !== ids.lenght - 1) {
      return `/blog/${ids[position + 1]}`;
    }

    return `/blog/${id}`;
  };

  nextPost = (id) => {
    const { ids } = this.state;

    const position = ids.indexOf(id);

    if (position !== 0) {
      return `/blog/${ids[position - 1]}`;
    }

    return `/blog/${id}`;
  };

  renderBanner = () => {
    const { post } = this.state;
    if (!!post) {
      if (!!post._embedded['wp:featuredmedia']) {
        return (
          <Banner image={post._embedded['wp:featuredmedia'][0].source_url}>
            <div />
            <h2>{post.title.rendered}</h2>
          </Banner>
        );
      }
      return (
        <Banner image="https://via.placeholder.com/1000x500?text=+">
          <h2>{post.title.rendered}</h2>
        </Banner>
      );
    }
    return <Banner image="https://via.placeholder.com/1000x500?text=+" />;
  };

  render() {
    const {
      post, tags, author, loading,
    } = this.state;

    return (
      <Container>
        <Header />
        {this.renderBanner()}
        <Content>
          <div className="content__header">
            {!!author && (
              <Author>
                <img src={author.acf.foto.url} alt="avatar" />
                <p>
                  Postado por
                  {' '}
                  <span>{author.name}</span>
                </p>
              </Author>
            )}

            {!!post && <span>{Moment(post.date).format('LLL')}</span>}
          </div>

          {!!post ? (
            <div className="content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          ) : (
            <div style={{ minHeight: '400px' }} />
          )}

          {!!post && (
            <div className="content__share">
              <div className="share">
                <span>Compartilhe: </span>
                <ShareSection
                  href={`https://facebook.com/sharer/sharer.php?u=https://miquelao.com.br/blog/${
                    post.slug
                  }`}
                  target="_blank"
                  rel="noreferrer noopener"
                  color="#5661E3"
                >
                  <img src={FacebookIcon} alt="Facebook" />
                </ShareSection>
                <ShareSection
                  href={`https://twitter.com/intent/tweet?text=${post.title.rendered.replace(
                    ' ',
                    '%20',
                  )}&url=https://miquelao.com.br/blog/${post.slug}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  color="#29E1FF"
                >
                  <img src={TwitterIcon} alt="Twitter" />
                </ShareSection>
                <ShareSection
                  href={`http://www.linkedin.com/shareArticle?mini=true&url=https://miquelao.com.br/blog/${
                    post.slug
                  }&title=${post.title.rendered.replace(' ', '%20')}&source=miquelao.com.br`}
                  color="#2E3986"
                >
                  <img src={LinkedinIcon} alt="Linkedin" />
                </ShareSection>
                <ShareSection
                  href={`mailto:?subject=${post.title.rendered.replace(
                    ' ',
                    '%20',
                  )}&body=https://miquelao.com.br/blog/${post.slug}`}
                  color="#36D875"
                >
                  <img src={EmailIcon} alt="Email" />
                </ShareSection>
              </div>

              <div className="tags">
                <span>Tags: </span>
                {!!tags
                  && tags.map(tag => (
                    <div key={tag.id} className="tag">
                      <span>{tag.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {!!post && (
            <div className="actions">
              <Link to={this.prevPost(post.slug)} className="prev">
                <img src={ArrowRight} alt="Seta" />
                Anterior
              </Link>
              <Link to={this.nextPost(post.slug)} className="next">
                Próximo
                {' '}
                <img src={ArrowRight} alt="Seta" />
              </Link>
            </div>
          )}
        </Content>
        <Contato />
        <Footer />
      </Container>
    );
  }
}

export default Post;
