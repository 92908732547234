import React, { Component, Fragment } from 'react';
import ContentLoader from 'react-content-loader';
import api from '../../services/api';

import {
  Container, Content, About, Valores,
} from './styles';

import Header from '../../components/Header';
import Contato from '../../components/Contato';
import Footer from '../../components/Footer';

class Sobre extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    // Code...
    document.title = 'Sobre nós - Construtora Miquelão';
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    this.getData();
  }

  getData = async () => {
    const response = await api.get('/pages/7');

    this.setState({ data: response.data });
  };

  render() {
    const { data } = this.state;

    return (
      <Fragment>
        <Header showBorder marginBottom="80px" />
        <Container>
          <Content>
            <About>
              {!!data ? (
                <div className="square">
                  <h2>{data.title.rendered}</h2>
                </div>
              ) : (
                <div className="square" style={{ backgroundColor: '#f3f3f3' }} />
              )}

              {!!data ? (
                <div
                  className="about__content"
                  dangerouslySetInnerHTML={{ __html: data.content.rendered }}
                />
              ) : (
                <>
                  <ContentLoader
                    height={400}
                    width={1000}
                    style={{ marginTop: '125px' }}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                  >
                    <rect x="10" y="25" rx="4" ry="4" width="970" height="11.96" />
                    <rect x="10" y="40" rx="4" ry="4" width="950" height="11.96" />
                    <rect x="10" y="55" rx="4" ry="4" width="900" height="11.96" />
                    <rect x="10" y="70" rx="4" ry="4" width="920" height="11.96" />
                    <rect x="10" y="85" rx="4" ry="4" width="870" height="11.96" />
                    <rect x="10" y="115" rx="4" ry="4" width="970" height="11.96" />
                    <rect x="10" y="130" rx="4" ry="4" width="900" height="11.96" />
                    <rect x="10" y="145" rx="4" ry="4" width="920" height="11.96" />
                    <rect x="10" y="160" rx="4" ry="4" width="870" height="11.96" />
                  </ContentLoader>

                  <ContentLoader
                    height={400}
                    width={1000}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                  >
                    <rect x="10" y="25" rx="4" ry="4" width="970" height="11.96" />
                    <rect x="10" y="40" rx="4" ry="4" width="950" height="11.96" />
                    <rect x="10" y="55" rx="4" ry="4" width="900" height="11.96" />
                    <rect x="10" y="70" rx="4" ry="4" width="920" height="11.96" />
                    <rect x="10" y="85" rx="4" ry="4" width="870" height="11.96" />
                    <rect x="10" y="115" rx="4" ry="4" width="970" height="11.96" />
                    <rect x="10" y="130" rx="4" ry="4" width="900" height="11.96" />
                    <rect x="10" y="145" rx="4" ry="4" width="920" height="11.96" />
                    <rect x="10" y="160" rx="4" ry="4" width="870" height="11.96" />
                  </ContentLoader>
                </>
              )}
            </About>
          </Content>
          <Valores>
            {!!data
              && data.acf.valores.map(valor => (
                <div key={Math.random()}>
                  <img src={valor.icone.url} alt={valor.titulo} />
                  <h3>{valor.titulo}</h3>
                  <span dangerouslySetInnerHTML={{ __html: valor.texto }} />
                </div>
              ))}
          </Valores>
          <Contato />
          <Footer />
        </Container>
      </Fragment>
    );
  }
}

export default Sobre;
