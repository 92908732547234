import { createGlobalStyle } from 'styled-components';

import './fonts.css';
import 'normalize.css';
import 'slick-carousel/slick/slick.css';
import 'flickity/css/flickity.css';
import '@fancyapps/fancybox/src/css/core.css';
import 'rc-slider/assets/index.css';
import 'hamburgers/dist/hamburgers.css';
import 'rc-pagination/assets/index.css';

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: 0;
    }

    body {
      font-family: 'Libre Franklin';
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
      overflow-x: hidden;
    }

    button {
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }
`;

export default GlobalStyle;
