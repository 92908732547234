import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 120px;
`;

export const Banner = styled.section`
  width: 100%;
  height: 390px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const Title = styled.div`
  width: 80%;
  max-width: 1240px;
  margin: auto;
  font-weight: normal;
  color: #4a4a4a;

  h2 {
    font-family: 'Noirden';
    font-size: 45px;
    margin-bottom: 12px;
    margin-top: 125px;
  }

  span {
    margin-left: 7px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const Form = styled.form`
  width: 80%;
  max-width: 1240px;
  margin: auto;

  h3 {
    font-family: 'Noirden';
    font-size: 30px;
    font-weight: 300;
    color: #33499c;
    margin-top: 115px;
  }

  .pessoais {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 50px;
    margin-top: 50px;
  }

  .contato__first__line {
    display: grid;
    grid-template-columns: 2fr 1fr 0.5fr 1.5fr;
    grid-gap: 50px;
    margin-top: 50px;
  }

  .contato__second__line {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    grid-gap: 50px;
    margin-top: 50px;
  }

  .academicas {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    grid-gap: 50px;
    margin-top: 50px;
  }

  .situacao__pretencao {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  small {
    font-size: 12px;
    font-weight: 200;
    color: #4a4a4a;
  }

  .form__success {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    color: white;
    background: green;
    margin-top: 40px;
  }

  .anexo__send {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;

    .bg {
      position: relative;
      line-height: 16px;
      font-size: 13px;
      text-transform: uppercase;
      cursor: pointer;
      border: none;
      color: #fff;

      width: 190px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin-left: 30px;

      img {
        height: 8px;
        padding: unset;
        margin: 0 10px;
      }

      &:before {
        content: '';
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
      }
    }

    > div {
      p {
        margin-top: 15px;
      }

      > div {
        display: flex;
        align-items: center;
      }

      span {
        font-size: 20px;
        font-weight: bold;
        color: #4a4a4a;
        margin-right: 15px;
      }
    }
  }

  @media (max-width: 480px) {
    .pessoais,
    .contato__first__line,
    .contato__second__line,
    .academicas,
    .situacao__pretencao {
      grid-template-columns: 1fr;
    }

    .anexo__send {
      flex-direction: column;

      > div {
        flex-direction: column;
        align-items: center;

        span {
          margin: unset;
          text-align: center;
        }

        label {
          margin-top: 20px;
        }
      }

      button {
        align-self: center;
        margin-top: 20px;
      }
    }
  }
`;
