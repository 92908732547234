import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ademail from '../../services/ademail';

import { Container, Form } from './styles';

import ArrowRight from '../../assets/images/arrow-point-to-right.svg';

const styles = () => ({
  container: {
    display: 'grid',
    flexTemplateColumns: '1fr 1fr 1fr',
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  cssLabel: {
    fontFamily: 'Libre Franklin',
    fontSize: 15,
    '&$cssFocused': {
      color: '#6ABD45',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#6ABD45',
    },
  },
});

class Contato extends Component {
  state = {
    nome: '',
    email: '',
    telefone: '',
    mensagem: '',
    sending: false,
    status: null,
  };

  render() {
    const { classes, empreendimento } = this.props;
    const {
      nome, email, telefone, mensagem, sending, status,
    } = this.state;

    const handleSubmit = async (e) => {
      this.setState({ sending: true });

      e.preventDefault();
      const body = `<p>Nome: ${nome}</p>
        <p>Email: ${email}</p>
        <p>Telefone: ${telefone}</p>
        ${empreendimento ? `<p>Empreendimento: ${empreendimento}</p>` : null}
        <p>Mensagem: ${mensagem}</p>`;

      const mail = {
        to: 'anarubia@miquelao.com.br',
        from: email,
        as: nome,
        cc: JSON.stringify(['fabio@miquelao.com.br']),
        subject: 'Novo contato via formulário do site - Miquelão',
        message: body,
      };

      const mailResponse = await ademail.post('/email', mail);

      if (mailResponse.status === 200) {
        this.setState({
          nome: '',
          email: '',
          telefone: '',
          mensagem: '',
          sending: false,
          status: 'success',
        });
      }
    };

    return (
      <Container id="contato">
        <h2>Contato</h2>
        <span>
          Dúvidas ou sugestões? Mande-nos uma mensagem que retornaremos o mais rápido possivel.
        </span>

        <Form onSubmit={e => handleSubmit(e)}>
          <div>
            <TextField
              id="standard-name"
              label="NOME"
              value={nome}
              onChange={e => this.setState({ nome: e.target.value })}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              required
            />
            <TextField
              id="standard-name"
              label="EMAIL"
              type="email"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              required
            />
            <TextField
              id="standard-name"
              label="TELEFONE"
              value={telefone}
              onChange={e => this.setState({ telefone: e.target.value })}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              required
            />
          </div>

          <TextField
            id="standard-multiline-flexible"
            label="MENSAGEM"
            value={mensagem}
            onChange={e => this.setState({ mensagem: e.target.value })}
            multiline
            rows="3"
            rowsMax="4"
            // value={this.state.multiline}
            // onChange={this.handleChange('multiline')}
            className={classes.textArea}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                underline: classes.cssUnderline,
              },
            }}
            margin="normal"
            required
          />
          {status === 'success' && <div className="form__success">Contato enviado com sucesso</div>}
          <div>
            <div className="infos">
              <span>
                construtora@
                <span>miquelao</span>
                .com.br
              </span>
              <span>Rua Sete de Setembro, 800 | Centro | Ponta Grossa | PR | CEP: 84010-350</span>
              <span>42 3224-5121</span>
            </div>

            <button disabled={sending} type="submit">
              Enviar
              {' '}
              <img src={ArrowRight} alt="Seta" />
            </button>
          </div>
        </Form>
      </Container>
    );
  }
}
export default withStyles(styles)(Contato);
