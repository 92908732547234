import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/pt_BR';
import api from '../../services/api';

import {
  Container, Banner, Recentes, Posts, Post, Antigas,
} from './styles';

import Header from '../../components/Header';
import Contato from '../../components/Contato';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';

import ArrowRight from '../../assets/images/arrow-point-to-right.svg';
// Pq existe tanto projeto
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 150,
  },
  cssLabel: {
    fontFamily: 'Libre Franklin',
    fontSize: 15,
    '&$cssFocused': {
      color: '#6ABD45',
    },
  },
  cssFocused: {
    color: '#6abd45',
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#6ABD45',
    },
  },
});

class Blog extends Component {
  state = {
    posts: null,
    month: '',
    year: '',
    currentPage: 1,
    totalPosts: 0,
    oldPosts: null,
    loading: false,
  };

  componentDidMount() {
    document.title = 'Blog - Construtora Miquelão';
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    this.setState({ loading: true });
    this.getPostsData().then(() => {
      this.setState({ loading: false });
    });
  }

  getPostsData = async () => {
    const response = await api.get('/posts?_embed&per_page=12');

    this.setState({
      posts: response.data,
      oldPosts: response.data,
      totalPosts: response.headers['x-wp-total'],
    });
  };

  onChangePage = async (page) => {
    this.setState({ oldPosts: null });
    const response = await api.get(`/posts?_embed&per_page=12&page=${page}`);

    this.setState({ currentPage: page, oldPosts: response.data });
  };

  renderYearInput = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 2014; i -= 1) {
      years.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>,
      );
    }
    return years;
  };

  render() {
    const { classes } = this.props;
    const {
      posts, oldPosts, currentPage, totalPosts, loading,
    } = this.state;

    return (
      <Container>
        <Header />

        {!!posts ? (
          <Banner image={posts[0]._embedded['wp:featuredmedia'][0].source_url}>
            <h2>{posts[0].title.rendered}</h2>
            <span dangerouslySetInnerHTML={{ __html: posts[0].excerpt.rendered }} />
            <Link to={`/blog/${posts[0].slug}`}>
              Leia mais
              {' '}
              <img src={ArrowRight} alt="Seta" />
            </Link>
          </Banner>
        ) : (
          <div style={{ height: '730px', width: '100%', background: '#f3f3f3' }} />
        )}

        <Recentes>
          <h2>Postagens recentes</h2>
          <Posts>
            {!!posts ? (
              posts.map((post, index) => {
                if (index !== 0 && index <= 3) {
                  return (
                    <Post key={post.id} to={`/blog/${post.slug}`}>
                      {!!post._embedded['wp:featuredmedia'] ? (
                        <img src={post._embedded['wp:featuredmedia'][0].source_url} alt="Blog" />
                      ) : (
                        <img src="https://via.placeholder.com/340x190?text=Sem+Imagem" alt="Blog" />
                      )}

                      <h3>{post.title.rendered}</h3>
                      <span dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                      <button className="bg">
                        Leia mais
                        {' '}
                        <img src={ArrowRight} alt="Seta" />
                      </button>
                    </Post>
                  );
                }
              })
            ) : (
              <>
                <ContentLoader
                  height={455}
                  width={345}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                  // {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="345" height="230" />
                  <rect x="22.5" y="275" rx="4" ry="4" width="300" height="14" />
                  <rect x="22.5" y="295" rx="4" ry="4" width="300" height="14" />
                  <rect x="10" y="330" rx="3" ry="3" width="326.8" height="8.51" />
                  <rect x="10" y="345" rx="3" ry="3" width="325" height="9" />
                  <rect x="10" y="360" rx="3" ry="3" width="270" height="9" />
                  <rect x="10" y="375" rx="3" ry="3" width="300" height="9" />
                  <rect x="10" y="390" rx="3" ry="3" width="245" height="9" />
                </ContentLoader>
                <ContentLoader
                  height={455}
                  width={345}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                  // {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="345" height="230" />
                  <rect x="22.5" y="275" rx="4" ry="4" width="300" height="14" />
                  <rect x="22.5" y="295" rx="4" ry="4" width="300" height="14" />
                  <rect x="10" y="330" rx="3" ry="3" width="326.8" height="8.51" />
                  <rect x="10" y="345" rx="3" ry="3" width="325" height="9" />
                  <rect x="10" y="360" rx="3" ry="3" width="270" height="9" />
                  <rect x="10" y="375" rx="3" ry="3" width="300" height="9" />
                  <rect x="10" y="390" rx="3" ry="3" width="245" height="9" />
                </ContentLoader>
                <ContentLoader
                  height={455}
                  width={345}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                  // {...this.props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="345" height="230" />
                  <rect x="22.5" y="275" rx="4" ry="4" width="300" height="14" />
                  <rect x="22.5" y="295" rx="4" ry="4" width="300" height="14" />
                  <rect x="10" y="330" rx="3" ry="3" width="326.8" height="8.51" />
                  <rect x="10" y="345" rx="3" ry="3" width="325" height="9" />
                  <rect x="10" y="360" rx="3" ry="3" width="270" height="9" />
                  <rect x="10" y="375" rx="3" ry="3" width="300" height="9" />
                  <rect x="10" y="390" rx="3" ry="3" width="245" height="9" />
                </ContentLoader>
              </>
            )}
          </Posts>
        </Recentes>

        <Antigas>
          <div>
            <h2>Postagens antigas</h2>
          </div>

          <div className="old__posts">
            {!!oldPosts ? (
              oldPosts.map(post => (
                <Link key={post.id} to={`/blog/${post.slug}`}>
                  {post.title.rendered}
                </Link>
              ))
            ) : (
              <>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={50}
                  width={310}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="10" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="10" y="30" rx="4" ry="4" width="270" height="13" />
                </ContentLoader>
              </>
            )}
          </div>

          <Pagination
            locale={localeInfo}
            onChange={this.onChangePage}
            defaultPageSize={12}
            current={currentPage}
            total={parseInt(totalPosts, 10)}
            style={{ marginTop: '35px', float: 'right' }}
          />
        </Antigas>

        <Contato />
        <Footer />
      </Container>
    );
  }
}

export default withStyles(styles)(Blog);
