import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 120px;
`;

export const Banner = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  height: 400px;
  background: url(${props => props.image}) no-repeat top center;
  background-size: cover;
  padding-left: 15%;

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    z-index: 1;
  }

  h2 {
    font-family: 'Noirden';
    font-size: 56px;
    font-weight: normal;
    letter-spacing: 2.5px;
    color: #fff;
    width: 500px;
    z-index: 2;
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 35px;
    }
  }
`;

export const Content = styled.section`
  width: 100%;
  max-width: 700px;
  margin: auto;

  @media (max-width: 480px) {
    width: 80%;
    max-width: 80%;
  }

  .content__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    > span {
      font-size: 12px;
      font-weight: normal;
      color: #4a4a4a;
    }
  }

  .content {
    * {
      max-width: 100%;
    }

    margin-top: 60px;

    p {
      margin-top: 35px;
      font-size: 18px;
      line-height: 30px;


      img {
        height: auto;
      }
    }
    .wp-caption {
      width: 480px;
      margin: auto;

      @media (max-width: 480px) {
        width: 100%;
      }
      
      img {
        width: 100%;
        height: auto;
      }
      
      .wp-caption-text {
        margin-top: 10px;
        font-size: 14px;
        text-align: center;
        font-style: italic;
      }
    } 

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 45px;
    }
  }

  .content__share {
    display: flex;
    margin-top: 80px;

    .share {
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: bold;
        color: #4a4a4a;
        margin-right: 20px;
      }
    }

    .tags {
      display: flex;
      align-items: center;
      margin-left: 60px;

      > span {
        font-size: 18px;
        font-weight: bold;
        color: #4a4a4a;
        margin-right: 20px;
      }

      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 0 20px;
        background: #cfcfcf;
        margin-right: 5px;

        span {
          font-size: 15px;
          font-weight: normal;
          color: #4a4a4a;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
    font-size: 13px;
    font-weight: 500;
    color: #4a4a4a;

    .next {
      position: relative;
      line-height: 16px;
      text-transform: uppercase;
      color: #fff;

      width: 190px;
      height: 30px;
      background: ${props => (props.disabled ? '#C5C5C5' : '#6abd45')};
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin-left: 30px;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: unset;
        margin: 0 10px;
      }

      &:before {
        content: '';
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid ${props => (props.disabled ? '#C5C5C5' : '#6abd45')};
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: ${props => (props.disabled ? '#C5C5C5' : '#84d660')};

        &:before {
          border-bottom-color: ${props => (props.disabled ? '#C5C5C5' : '#84d660')};
        }
      }
    }

    .prev {
      position: relative;
      line-height: 16px;
      text-transform: uppercase;
      color: #fff;

      width: 190px;
      height: 30px;
      background: ${props => (props.disabled ? '#C5C5C5' : '#6abd45')};
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-start;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: unset;
        margin: 0 10px;
        transform: rotate(180deg);
      }

      &:before {
        content: '';
        position: absolute;
        right: -30px;
        width: 0px;
        height: 0px;
        border-right: 30px solid transparent;
        border-bottom: 30px solid ${props => (props.disabled ? '#C5C5C5' : '#6abd45')};
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: ${props => (props.disabled ? '#C5C5C5' : '#84d660')};

        &:before {
          border-bottom-color: ${props => (props.disabled ? '#C5C5C5' : '#84d660')};
        }
      }
    }
  }

  @media (max-width: 480px) {
    .actions {
      flex-direction: column;
      height: 80px;
    }
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  color: #4a4a4a;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1.5px solid #6abd45;
    object-fit: cover;
    margin-right: 10px;
  }

  span {
    font-weight: bold;
    color: #6abd45;
  }
`;

export const ShareSection = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  background: ${props => props.color};
  cursor: pointer;

  img {
    height: 15px;
  }

  &:hover {
    opacity: 0.7;
  }
`;
