import React, { Fragment } from 'react';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import Routes from './routes';

import GlobalStyle from './styles/global';

import Popup from './components/Popup';

import './config/reactotron';

const history = createBrowserHistory();

// Inicializar o analytics
const trackingId = 'UA-59244762-1'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

// Inicializar o GTM
const tagManagerArgs = {
  gtmId: 'GTM-KRXM8SJ',
};
TagManager.initialize(tagManagerArgs);

// Inicializar o Facebook Pixel
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('837287038561410', options);
ReactPixel.pageView(); // For tracking page view

const App = () => (
  <Router history={history}>
    <Fragment>
      <GlobalStyle />
      <Popup />
      <Routes />
    </Fragment>
  </Router>
);

export default App;
