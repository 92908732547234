import styled from "styled-components";

export const Container = styled.section`
  position: relative;
  width: 80%;
  max-width: 1240px;
  margin: auto;
  padding-top: 125px;

  h2 {
    font-family: "Noirden";
    font-size: 45px;
    color: #4a4a4a;
    margin-bottom: 25px;

    &:after {
      content: "";
      position: absolute;
      height: 160px;
      width: 1px;
      background: #4a4a4a;
      top: -30px;
      right: -8%;
      transform: rotate(40deg);
    }
  }

  span {
    margin-top: 15px;
    font-size: 15px;
    color: #4a4a4a;
  }

  @media (max-width: 480px) {
    h2:after {
      display: none;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 90px;

  .form__success {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    color: white;
    background: green;
    margin-top: 40px;
  }

  > div {
    &:first-child {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 40px;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;

      .infos {
        display: flex;
        flex-direction: column;

        > span {
          font-size: 15px;
          color: #4a4a4a;

          span {
            font-weight: bold;
          }
        }
      }

      button {
        position: relative;
        margin-left: 30px;
        margin-top: 35px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: #fff;
        border: none;

        width: 220px;
        height: 30px;
        background: #6abd45;
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: flex-end;
        z-index: 8;
        transition: all ease-in 0.2s;

        img {
          height: 8px;
          padding: 0 10px;
        }

        > div {
          top: -4px;
          left: -30px;
          color: #fff;
          transform: scale(0.45);
        }

        &:before {
          content: "";
          position: absolute;
          left: -30px;
          width: 0px;
          height: 0px;
          border-left: 30px solid transparent;
          border-bottom: 30px solid #6abd45;
          transition: all ease-in 0.2s;
        }

        &:hover {
          background: #84d660;

          &:before {
            border-bottom-color: #84d660;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
    > div {
      &:first-child {
        grid-template-columns: 1fr;
      }
      &:last-child {
        flex-direction: column-reverse;

        .infos {
          text-align: center;
          margin-top: 20px;
        }

        button {
          width: 160px;
        }
      }
    }
  }

  @media (max-width: 320px) {
  }
`;
