import styled from "styled-components";

export const Container = styled.header`
  .sticky {
    height: 75px;
    /* border-bottom: 2px solid #6abd45; */
    transition: all ease-in 0.3s;

    img {
      height: 65px;
    }

    button {
      @media (max-width: 480px) {
        top: 12px;
        transition: all ease-in 0.3s;
      }
    }
  }

  div {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    padding: 0 3%;
    border-bottom: ${props =>
      props.showBorder ? "2px solid #6abd45" : "unset"};
    margin-bottom: ${props => props.marginBottom};
    background: #fff;
    z-index: 9;
    transition: all ease-in-out 0.3s;

    img {
      height: 80px;
      transition: all ease-in 0.3s;
    }

    button {
      display: none;

      > span {
        span {
          background-color: #6abd45;

          &:before,
          &:after {
            background-color: #6abd45;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .menu__open {
      flex-direction: column;
      justify-content: flex-start;
      height: 100vh;
      position: fixed;

      a {
        align-self: flex-start;
        margin-top: 19px;
      }

      ul {
        display: unset;
        text-align: center;

        li {
          margin: unset;
          margin-top: 30px;
          font-size: 14px;
        }
      }
    }

    div {
      button {
        position: absolute;
        top: 35px;
        right: 15px;
        display: unset;
      }
    }
  }
`;

export const Nav = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  font-size: 12px;
  font-weight: ${props => (props.active ? "900" : "500")};
  text-transform: uppercase;
  list-style: none;
  margin-left: 30px;
  transition: all ease-in-out 0.3s;

  a {
    position: relative;
    color: #4a4a4a;

    &:hover:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #4a4a4a;
    }
  }

  .bg {
    position: relative;
    line-height: 16px;
    text-transform: uppercase;
    color: #fff;

    width: 200px;
    height: 30px;
    background: #6abd45;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    margin-left: 30px;
    transition: all ease-in 0.2s;

    img {
      height: 8px;
      padding: unset;
      margin: 0 10px;
    }

    &:before {
      content: "";
      position: absolute;
      left: -30px;
      width: 0px;
      height: 0px;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #6abd45;
      transition: all ease-in 0.2s;
    }

    &:hover {
      background: #84d660;

      &:before {
        border-bottom-color: #84d660;
      }
    }
  }
`;
