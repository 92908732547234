import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 200px;
`;

export const Content = styled.section`
  position: relative;
  background: #f3f3f3;
`;

export const About = styled.div`
  width: 80%;
  max-width: 1240px;
  margin: auto;

  .square {
    display: flex;
    align-items: center;
    position: absolute;
    top: -30px;
    left: 0;
    height: 155px;
    width: 70%;
    padding-left: 10%;
    background: #6abd45;

    h2 {
      font-family: 'Noirden';
      font-size: 53px;
      font-weight: 300;
      width: 490px;
      color: #fff;
    }
  }

  .about__content {
    padding-top: 125px;
    padding-bottom: 50px;

    p {
      font-size: 15px;
      line-height: 25px;
      color: #4a4a4a;
      margin-top: 30px;
    }

    iframe {
      margin-top: 50px;
    }
  }

  @media (max-width: 992px) {
    .square {
      h2 {
        font-size: 44px;
      }
    }
  }

  @media (max-width: 768px) {
    .square {
      h2 {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 480px) {
    .square {
      width: 100%;
      padding: 0 10%;
      h2 {
        font-size: 30px;
        text-align: center;
      }
    }

    .about__content {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }

  @media (max-width: 320px) {
    .square {
      h2 {
        font-size: 32px;
      }
    }
  }
`;

export const Video = styled.section`
  position: relative;
  width: 80%;
  max-width: 1240px;
  margin: auto;
  margin-top: -200px;

  @media (max-width: 480px) {
    margin-bottom: -200px;
  }

  @media (max-width: 320px) {
  }
`;

export const Valores = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 90px;
  width: 80%;
  max-width: 1240px;
  margin: auto;
  margin-top: 120px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 70px;
    }

    h3 {
      font-family: 'Noirden';
      font-size: 45px;
      font-weight: normal;
      color: #33499c;
      margin-top: 45px;
    }

    span {
      font-size: 15px;
      font-weight: normal;
      color: #4a4a4a;
      margin-top: 30px;
      line-height: 23px;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    span {
      text-align: center;
    }
  }
`;
