import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import SmoothScroll from 'smooth-scroll/dist/smooth-scroll';

import { Container, Nav, NavItem } from './styles';

import Logo from '../../assets/images/New_logo.png';
import ArrowRight from '../../assets/images/arrow-point-to-right.svg';

class Header extends Component {
  static defaulProps = {
    showBorder: false,
    marginBottom: 'unset',
  };

  componentDidMount() {
    // Get the header
    const header = document.getElementById('header-id');

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // When the user scrolls the page, execute addClassWhenScroll
    window.onscroll = () => {
      this.addClassWhenScroll(sticky, header);
    };

    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 500,
      speedAsDuration: true,
    });
  }

  addClassWhenScroll = (sticky, header) => {
    /* Add the sticky class to the header when you reach its scroll position.
       Remove "sticky" when you leave the scroll position */
    if (window.pageYOffset > sticky) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  };

  render() {
    const { showBorder, marginBottom } = this.props;

    return (
      <Container showBorder={showBorder} marginBottom={marginBottom}>
        <div id="header-id">
          <Link to="/">
            <img src={Logo} alt="Logo Miquelãoooo" />
          </Link>
          <Nav>
            <NavItem id="navSobre">
              <Link to="/sobre">Sobre</Link>
            </NavItem>
            <NavItem>
              <Link id="navEmpreendimentos" to="/#empreendimentos">
                Empreendimentos
              </Link>
            </NavItem>
            {/* <NavItem id="navPortifolio">
              <Link to="/#portifolio">Portfólio</Link>
            </NavItem> */}
            <NavItem id="navContato">
              <a href="/#contato">Contato</a>
            </NavItem>
            <NavItem id="navTrabalheConosco">
              <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
            </NavItem>
            <NavItem id="navBlog">
              <Link to="/blog">Blog</Link>
            </NavItem>
            <NavItem>
              <button
                onClick={() => window.open('https://ofertas.miquelao.com.br/')}
                style={{ border: 'none' }}
                className="bg"
              >
                Compre um apê miquelão
                {' '}
                <img src={ArrowRight} alt="Seta" />
              </button>
            </NavItem>
          </Nav>
          <button
            onClick={() => {
              const element = document.getElementById('header-id');
              const buttonElement = document.getElementById('hamburger-button');
              if (
                element.classList[0] === 'menu__open'
                || element.classList[1] === 'menu__open'
              ) {
                element.classList.remove('menu__open');
                buttonElement.classList.remove('is-active');
              } else {
                element.classList.add('menu__open');
                buttonElement.classList.add('is-active');
              }
            }}
            id="hamburger-button"
            className="hamburger hamburger--collapse"
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </Container>
    );
  }
}
export default Header;
