import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ademail from '../../services/ademail';

import {
  Container, Banner, Title, Form,
} from './styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ErrorFocus from '../../components/ErrorFocus';

import BannerImage from '../../assets/images/banner-trabalhe-conosco.jpg';
import ArrowRight from '../../assets/images/arrow-point-to-right.svg';

const styles = () => ({
  container: {
    display: 'grid',
    flexTemplateColumns: '1fr 1fr 1fr',
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  cssLabel: {
    fontFamily: 'Libre Franklin',
    fontSize: 15,
    '&$cssFocused': {
      color: '#6ABD45',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#6ABD45',
    },
  },
});

const handleFileInput = () => {
  console.log('Change input');
  const file = document.getElementById('file').files;
  if (!!file) document.getElementById('fileNameInput').innerHTML = file[0].name;
};

const TrabalheConosco = ({
  classes,
  handleChange,
  handleSubmit,
  setFieldValue,
  values,
  errors,
  status,
}) => {
  useEffect(() => {
    document.title = 'Trabalhe conosco - Construtora Miquelão';
  }, []);

  return (
    <Container>
      <Header />

      <Banner>
        <img src={BannerImage} alt="Banner" />
      </Banner>
      <Title>
        <h2>Trabalhe Conosco</h2>
        <span>
          Preencha os dados e anexe o seu currículo e venha participar da melhor
          equipe de construtora da região.
        </span>
      </Title>
      <Form onSubmit={handleSubmit}>
        <h3>Informações pessoais</h3>
        <div className="pessoais">
          {/* NOME  */}
          <FormControl className={classes.formControl} error={!errors.nome}>
            <TextField
              id="standard-name"
              label="NOME*"
              name="nome"
              onChange={handleChange}
              value={values.nome}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.nome}
            />
            <FormHelperText id="component-error-text">
              {errors.nome}
            </FormHelperText>
          </FormControl>

          {/* DATA DE NASCIMENTO */}
          <FormControl
            className={classes.formControl}
            error={!!errors.nascimento}
          >
            <TextField
              id="standard-nasc"
              label="NASCIMENTO*"
              name="nascimento"
              onChange={handleChange}
              value={values.nascimento}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.nascimento}
            />
            <FormHelperText id="component-error-text">
              {errors.nascimento}
            </FormHelperText>
          </FormControl>

          {/* SEXO */}
          <FormGroup style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormLabel
              style={{ alignSelf: 'center', marginRight: 10 }}
              component="legend"
            >
              SEXO *
            </FormLabel>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={values.feminino}
                  style={{ color: '#6ABD45' }}
                  onChange={() => {
                    setFieldValue('feminino', !values.feminino);
                    setFieldValue('masculino', false);
                  }}
                  value={values.feminino ? 'feminino' : ''}
                  name="feminino"
                />
              )}
              label="F"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={values.masculino}
                  style={{ color: '#6ABD45' }}
                  onChange={() => {
                    setFieldValue('masculino', !values.masculino);
                    setFieldValue('feminino', false);
                  }}
                  value={values.masculino ? 'masculino' : ''}
                  name="masculino"
                />
              )}
              label="M"
            />
          </FormGroup>

          {/* CPF */}
          <FormControl className={classes.formControl} error={!!errors.cpf}>
            <TextField
              id="standard-cpf"
              label="CPF*"
              name="cpf"
              onChange={handleChange}
              value={values.cpf}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.cpf}
            />

            <FormHelperText id="component-error-text">
              {errors.cpf}
            </FormHelperText>
          </FormControl>

          {/* ESTADO CIVIL */}
          <FormControl
            className={classes.formControl}
            error={!!errors.estadoCivil}
          >
            <InputLabel
              htmlFor="month-helper"
              classes={{ root: classes.cssLabel, focused: classes.cssFocused }}
            >
              ESTADO CIVIL*
            </InputLabel>
            <Select
              style={{ width: '50%' }}
              name="estadoCivil"
              value={values.estadoCivil}
              onChange={handleChange}
              input={(
                <Input
                  id="estadoCivil-helper"
                  classes={{
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                    underline: classes.cssUnderline,
                  }}
                />
              )}
            >
              <MenuItem value="Solteiro">Solteiro</MenuItem>
              <MenuItem value="Casado">Casado</MenuItem>
              <MenuItem value="Divorciado">Divorciado</MenuItem>
            </Select>
            <FormHelperText id="component-error-text">
              {errors.estadoCivil}
            </FormHelperText>
          </FormControl>
        </div>

        <h3>Informações de contato</h3>
        <div className="contato__first__line">
          {/* ENDEREÇO */}
          <FormControl
            className={classes.formControl}
            error={!!errors.endereco}
          >
            <TextField
              id="standard-address"
              label="ENDEREÇO*"
              name="endereco"
              onChange={handleChange}
              value={values.endereco}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.endereco}
            />
            <FormHelperText id="component-error-text">
              {errors.endereco}
            </FormHelperText>
          </FormControl>

          {/* BAIRRO */}
          <FormControl className={classes.formControl} error={!!errors.bairro}>
            <TextField
              id="standard-address-bairro"
              label="BAIRRO*"
              name="bairro"
              onChange={handleChange}
              value={values.bairro}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.bairro}
            />
            <FormHelperText id="component-error-text">
              {errors.endereco}
            </FormHelperText>
          </FormControl>

          {/* UF */}
          <FormControl
            className={classes.formControl}
            margin="normal"
            error={!!errors.uf}
          >
            <InputLabel
              htmlFor="uf-helper"
              classes={{ root: classes.cssLabel, focused: classes.cssFocused }}
            >
              UF*
            </InputLabel>
            <Select
              name="uf"
              value={values.uf}
              onChange={handleChange}
              input={(
                <Input
                  name="uf"
                  id="uf-helper"
                  classes={{
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                    underline: classes.cssUnderline,
                  }}
                />
              )}
            >
              <MenuItem value="Acre">AC</MenuItem>
              <MenuItem value="Alagoas">AL</MenuItem>
              <MenuItem value="Amapá">AP</MenuItem>
              <MenuItem value="Bahia">BA</MenuItem>
              <MenuItem value="Ceará">CE</MenuItem>
              <MenuItem value="Destrito Federal">DF</MenuItem>
              <MenuItem value="Espírito Santo">ES</MenuItem>
              <MenuItem value="Goiás">GO</MenuItem>
              <MenuItem value="Minas Gerais">MG</MenuItem>
              <MenuItem value="Mato Grosso do Sul">MS</MenuItem>
              <MenuItem value="Mato Grosso">MT</MenuItem>
              <MenuItem value="Pará">PA</MenuItem>
              <MenuItem value="Paraíba">PB</MenuItem>
              <MenuItem value="Pernambuco">PE</MenuItem>
              <MenuItem value="Piauí">PI</MenuItem>
              <MenuItem value="Paraná">PR</MenuItem>
              <MenuItem value="Rio de Janeiro">RJ</MenuItem>
              <MenuItem value="Rio Grande do Norte">RN</MenuItem>
              <MenuItem value="Roraima">RO</MenuItem>
              <MenuItem value="Rondônia">RR</MenuItem>
              <MenuItem value="Rio Grande do Sul">RS</MenuItem>
              <MenuItem value="Santa Catarina">SC</MenuItem>
              <MenuItem value="Sergipe">SE</MenuItem>
              <MenuItem value="São Paulo">SP</MenuItem>
              <MenuItem value="Tocantins">TO</MenuItem>
            </Select>
            <FormHelperText id="component-error-text">
              {errors.uf}
            </FormHelperText>
          </FormControl>

          {/* CIDADE */}
          <FormControl className={classes.formControl} error={!!errors.cidade}>
            <TextField
              id="standard-city"
              label="CIDADE*"
              name="cidade"
              onChange={handleChange}
              value={values.cidade}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.cidade}
            />
            <FormHelperText id="component-error-text">
              {errors.cidade}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="contato__second__line">
          {/* CEP */}
          <FormControl className={classes.formControl} error={!!errors.cep}>
            <TextField
              id="standard-cep"
              label="CEP*"
              name="cep"
              onChange={handleChange}
              value={values.cep}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.cep}
            />
            <FormHelperText id="component-error-text">
              {errors.cep}
            </FormHelperText>
          </FormControl>

          {/* FONE */}
          <FormControl
            className={classes.formControl}
            error={!!errors.telefone}
          >
            <TextField
              id="standard-phone"
              label="FONE*"
              name="telefone"
              onChange={handleChange}
              value={values.telefone}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.telefone}
            />
            <FormHelperText id="component-error-text">
              {errors.telefone}
            </FormHelperText>
          </FormControl>

          {/* EMAIL */}
          <FormControl className={classes.formControl} error={!!errors.email}>
            <TextField
              id="standard-email"
              label="EMAIL*"
              type="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.email}
            />
            <FormHelperText id="component-error-text">
              {errors.email}
            </FormHelperText>
          </FormControl>
        </div>

        <h3>Informações acadêmicas</h3>
        <div className="academicas">
          {/* CURSO */}
          <FormControl className={classes.formControl} error={!!errors.curso}>
            <TextField
              id="standard-curso"
              label="CURSO*"
              name="curso"
              onChange={handleChange}
              value={values.curso}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.curso}
            />
            <FormHelperText id="component-error-text">
              {errors.curso}
            </FormHelperText>
          </FormControl>

          {/* INSTITUIÇÃO */}
          <FormControl
            className={classes.formControl}
            error={!!errors.instituicao}
          >
            <TextField
              id="standard-instituicao"
              label="INSTITUIÇÃO*"
              name="instituicao"
              onChange={handleChange}
              value={values.instituicao}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.instituicao}
            />
            <FormHelperText id="component-error-text">
              {errors.instituicao}
            </FormHelperText>
          </FormControl>

          {/* PERIODO */}
          <FormControl className={classes.formControl} error={!!errors.periodo}>
            <TextField
              id="standard-periodo"
              label="PERIODO*"
              name="periodo"
              onChange={handleChange}
              className={classes.textField}
              value={values.periodo}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.cssUnderline,
                },
              }}
              margin="normal"
              error={!!errors.periodo}
            />
            <FormHelperText id="component-error-text">
              {errors.periodo}
            </FormHelperText>
          </FormControl>

          {/* PÓS GRADUAÇÃO */}
          <TextField
            id="standard-pos"
            label="PÓS-GRADUAÇÃO"
            name="pos"
            onChange={handleChange}
            value={values.pos}
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                underline: classes.cssUnderline,
              },
            }}
            margin="normal"
          />
        </div>

        <h3>Situação profissional</h3>
        <div className="situacao__pretencao">
          {/* ULTIMO EMPREGO */}
          <TextField
            id="standard-last-job"
            label="ÚLTIMO EMPREGO"
            name="ultimoEmprego"
            onChange={handleChange}
            value={values.ultimoEmprego}
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                underline: classes.cssUnderline,
              },
            }}
            margin="normal"
          />

          {/* FUNÇÃO */}
          <TextField
            id="standard-funcao"
            label="FUNÇÃO"
            name="funcao"
            onChange={handleChange}
            value={values.funcao}
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                underline: classes.cssUnderline,
              },
            }}
            margin="normal"
          />
        </div>

        <h3>Pretenção e disponibilidade futura</h3>
        <div className="situacao__pretencao">
          {/* HORARIO DISPONIVEL */}
          <TextField
            id="standard-horario"
            label="HORÁRIO DISPONÍVEL"
            name="horarioDisponivel"
            onChange={handleChange}
            value={values.horarioDisponivel}
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                underline: classes.cssUnderline,
              },
            }}
            margin="normal"
          />

          {/* CARGO PRETENDIDO */}
          <TextField
            id="standard-cargo-pretendido"
            label="CARGO PRETENDIDO"
            name="cargoPretendido"
            onChange={handleChange}
            value={values.cargoPretendido}
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                underline: classes.cssUnderline,
              },
            }}
            margin="normal"
          />
        </div>
        <small>* Campos obrigatórios</small>

        <div className="anexo__send">
          <div>
            <div>
              <span>Envie seu currículo em doc. ou pdf.</span>
              <input
                accept=".doc, .docx, .pdf"
                className={classes.input}
                id="file"
                type="file"
                onChange={() => handleFileInput()}
                hidden
              />
              <label htmlFor="file">
                <div className="bg">
                  Escolher arquivo
                  <img src={ArrowRight} alt="Seta" />
                </div>
              </label>
            </div>
            <p id="fileNameInput" />
          </div>

          <button
            disabled={!!status && status.sending}
            type="submit"
            className="bg"
          >
            Enviar
            {' '}
            <img src={ArrowRight} alt="Seta" />
          </button>
        </div>
        {!!status && status.success && (
          <div className="form__success">Formulário enviado com sucesso</div>
        )}
        <ErrorFocus />
      </Form>
      <Footer />
    </Container>
  );
};

export default compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => ({
      nome: '',
      nascimento: '',
      feminino: true,
      masculino: false,
      cpf: '',
      estadoCivil: '',
      endereco: '',
      bairro: '',
      uf: '',
      cidade: '',
      cep: '',
      telefone: '',
      email: '',
      curso: '',
      instituicao: '',
      periodo: '',
      pos: '',
      ultimoEmprego: '',
      funcao: '',
      horarioDisponivel: '',
      cargoPretendido: '',
    }),

    validateOnBlur: false,
    validateOnChange: false,

    validationSchema: Yup.object().shape({
      nome: Yup.string().required('Campo Obrigatório'),
      nascimento: Yup.string().required('Campo Obrigatório'),
      cpf: Yup.string().required('Campo Obrigatório'),
      estadoCivil: Yup.string().required('Campo Obrigatório'),
      endereco: Yup.string().required('Campo Obrigatório'),
      bairro: Yup.string().required('Campo Obrigatório'),
      uf: Yup.string().required('Campo Obrigatório'),
      cidade: Yup.string().required('Campo Obrigatório'),
      cep: Yup.string().required('Campo Obrigatório'),
      telefone: Yup.string().required('Campo Obrigatório'),
      email: Yup.string()
        .email('Email inválido')
        .required('Campo Obrigatório'),
      curso: Yup.string().required('Campo Obrigatório'),
      instituicao: Yup.string().required('Campo Obrigatório'),
      periodo: Yup.string().required('Campo Obrigatório'),
    }),

    handleSubmit: async (values, { resetForm, setStatus }) => {
      setStatus({ sending: true, success: false });
      const body = `
        <br />
        <h5>Informações pessoais</h5>
        <p>Nome: ${values.nome}</p>
        <p>Data de nascimento: ${values.nascimento}</p>
        <p>Sexo: ${values.feminino ? 'Feminino' : 'Masculino'}</p>
        <p>CPF: ${values.cpf}</p>
        <p>Estado civil: ${values.estadoCivil}</p>

        <br />
        <h5>Informações de contato</h5>
        <p>Endereço: ${values.endereco}</p>
        <p>Estado: ${values.uf}</p>
        <p>Cidade: ${values.cidade}</p>
        <p>CEP: ${values.cep}</p>
        <p>Telefone: ${values.telefone}</p>
        <p>Email: ${values.email}</p>

        <br />
        <h5>Informações de acadêmicas</h5>
        <p>Curso: ${values.curso}</p>
        <p>Instituição: ${values.instituicao}</p>
        <p>Período: ${values.periodo}</p>
        <p>Pós-Graduação: ${values.pos}</p>

        <br />
        <h5>Situação profissional</h5>
        <p>Ultimo emprego: ${values.ultimoEmprego}</p>
        <p>Função: ${values.funcao}</p>

        <br />
        <h5>Pretenção e disponibilidade futura</h5>
        <p>Horário disponível: ${values.horarioDisponivel}</p>
        <p>Cargo pretendido: ${values.cargoPretendido}</p>
      `;

      // const cc = ['fabio@miquelao.com.br'];
      const attach = document.getElementById('file').files[0];

      const mail = new FormData();
      mail.append('to', 'anarubia@miquelao.com.br'); // anarubia@miquelao.com.br
      mail.append('from', values.email);
      mail.append('as', values.nome);
      // mail.append('cc', JSON.stringify(cc));
      mail.append('subject', 'Cadastro de currículo pelo site - Miquelão');
      mail.append('message', body);
      if (!!attach) mail.append('attach', attach);

      const config = {
        headers: {
          'Access-Control-Allow-Headers': '*',
          'content-type': 'multipart/form-data',
        },
      };

      try {
        await fetch('https://api.agenciaade.com.br/email-attach', {
          method: 'POST',
          body: mail,
          config,
        });
        setStatus({ sending: false, success: true });
      } catch (err) {
        console.log(err);
      }
    },
  }),
)(TrabalheConosco);
