/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Flickity from 'flickity';
import GoogleMapReact from 'google-map-react';
import Slider from 'rc-slider';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../services/api';

import {
  Container,
  Header,
  Menu,
  Nav,
  BannerMenor,
  Social,
  BannerDots,
  BannerMaior,
  Categorias,
  Empreendimentos,
  Empreendimento,
  Mapa,
  Blog,
  Posts,
  Post,
  CallToAction,
} from './styles';

import Contato from '../../components/Contato';
import Footer from '../../components/Footer';

import Logo from '../../assets/images/New_logo.png';
import ArrowRight from '../../assets/images/arrow-point-to-right.svg';
import ArrowRightBlack from '../../assets/images/arrow-point-to-right-black.svg';
import FacebookIcon from '../../assets/images/facebook-logo.svg';
import InstagramIcon from '../../assets/images/instagram-logo.svg';
import SlideIcon from '../../assets/images/slide.svg';
import MarkerIcon from '../../assets/images/marker.svg';

import CallToActionImage from '../../assets/images/calltoaction.jpg';

class Home extends Component {
  state = {
    data: null,
    empreendimentos: null,
    posts: null,
    categoria: 'lancamento',
    scrollPosition: 0,
    mapInfo: null,
    loading: false,
    bannerPosition: 0,
    mensagem: true,
  };

  componentDidMount() {
    const { location } = this.props;
    document.title = 'Construtora Miquelão';
    document.getElementsByTagName('body')[0].style.overflow = 'unset';

    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 500,
      speedAsDuration: true,
    });

    this.setState({ loading: true });
    this.getHomeData();

    this.getEmpreendimentosData();

    this.getBlogData().then(() => {
      setTimeout(() => {
        this.setState({ loading: false }, () => {
          this.flkty = new Flickity('#flickity', {
            draggable: true,
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            autoPlay: 5000,
            pauseAutoPlayOnHover: true,
          });

          this.flkty.on('change', (index) => {
            this.setState({ bannerPosition: index });
          });
        });
      }, 50);

      const element = document.getElementById('horizontal-scroll-posts');
      const post = document.getElementsByClassName('post-class');
      const postLength = post.length;
      const postWidth = post[0].clientWidth;
      const totalWidht = postWidth * postLength + 80 * (postLength - 1);

      element.addEventListener('scroll', () => {
        this.scrollToPercent(
          element.scrollLeft,
          totalWidht,
          element.offsetWidth,
        );
      });

      if (!!location.hash) {
        const anchor = document.querySelector(location.hash);
        setTimeout(() => {
          scroll.animateScroll(anchor);
        }, 500);
      }
    });

    const sticky = document.getElementById('header').offsetTop;
    const sticky2 = document.getElementById('headerMobile').offsetTop;

    window.onscroll = () => {
      this.addClassWhenScroll(sticky);
      this.addClassWhenScroll(sticky2);
    };
  }

  componentWillUnmount() {
    const element = document.getElementById('horizontal-scroll-posts');
    element.removeEventListener('scroll', this.scrollToPercent);
  }

  getHomeData = async () => {
    const response = await api.get('/pages/2');

    this.setState({ data: response.data });
  };

  getEmpreendimentosData = async () => {
    const response = await api.get('/empreendimentos?_embed');

    this.setState({ empreendimentos: response.data });
  };

  getBlogData = async () => {
    const response = await api.get('/posts?_embed');

    this.setState({ posts: response.data });
  };

  scrollToPercent = (scrollLeft, totalWidht, offsetWidth) => {
    const scrollPercent = Math.round(
      (scrollLeft / (totalWidht - offsetWidth)) * 1000,
    );
    this.setState({ scrollPosition: scrollPercent });
  };

  syncWithScroll = (value) => {
    this.setState({ scrollPosition: value });
    const element = document.getElementById('horizontal-scroll-posts');
    const post = document.getElementsByClassName('post-class');
    const postLength = post.length;
    const postWidth = post[0].clientWidth;
    const totalWidht = postWidth * postLength + 80 * (postLength - 1);

    element.scrollLeft = (value * (totalWidht - element.offsetWidth)) / 1000;
  };

  addClassWhenScroll = (sticky, sticky2) => {
    /* Add the sticky class to the header when you reach its scroll position.
       Remove "sticky" when you leave the scroll position */
    if (window.pageYOffset > sticky) {
      document.getElementById('header').classList.add('sticky');
    } else {
      document.getElementById('header').classList.remove('sticky');
    }

    if (window.pageYOffset > sticky2) {
      document.getElementById('headerMobile').classList.add('sticky');
    } else {
      document.getElementById('headerMobile').classList.remove('sticky');
    }
  };

  render() {
    const {
      data,
      categoria,
      empreendimentos,
      scrollPosition,
      mapInfo,
      posts,
      bannerPosition,
      loading,
      mensagem,
    } = this.state;

    let showMessage = true;

    return (
      <Container>
        {/* {this.renderPlaceholderEffect()} */}
        <div id="headerMobile" className="mobileNav">
          <Link to="/">
            <img className="logo" src={Logo} alt="Logo Miquelão" />
          </Link>

          <div id="mobile-menu">
            <ul>
              <li>
                <Link to="/sobre">Sobre</Link>
              </li>
              <li
                onClick={() => {
                  const element = document.getElementById('mobile-menu');
                  const buttonElement = document.getElementById(
                    'hamburger-button',
                  );
                  if (element.classList[0] === 'active') {
                    element.classList.remove('active');
                    buttonElement.classList.remove('is-active');
                  }
                }}
              >
                <a data-scroll href="#empreendimentos">
                  Empreendimentos
                </a>
              </li>
              <li
                onClick={() => {
                  const element = document.getElementById('mobile-menu');
                  const buttonElement = document.getElementById(
                    'hamburger-button',
                  );
                  if (element.classList[0] === 'active') {
                    element.classList.remove('active');
                    buttonElement.classList.remove('is-active');
                  }
                }}
              >
                <a data-scroll href="/#contato">
                  Contato
                </a>
              </li>
              <li>
                <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <a
                  onClick={() => window.open('https://ofertas.miquelao.com.br/')
                  }
                  className="bg__button"
                >
                  Compre um apê miquelão
                  {' '}
                  <img src={ArrowRight} alt="Seta" />
                </a>
              </li>
            </ul>
          </div>

          <button
            onClick={() => {
              const element = document.getElementById('mobile-menu');
              const buttonElement = document.getElementById('hamburger-button');
              if (element.classList[0] === 'active') {
                element.classList.remove('active');
                buttonElement.classList.remove('is-active');
              } else {
                element.classList.add('active');
                buttonElement.classList.add('is-active');
              }
            }}
            id="hamburger-button"
            className="hamburger hamburger--collapse"
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>

        <div className="nav" id="header">
          <div>
            <Menu justifyContent="space-between">
              <Link to="/">
                <img src={Logo} alt="Logo Miquelão" />
              </Link>
              <Nav>
                <li>
                  <Link to="/sobre">Sobre</Link>
                </li>
                <li>
                  <a data-scroll href="#empreendimentos">
                    Empreendimentos
                  </a>
                </li>
                {/* <li>
                  <a data-scroll href="#portifolio">
                    Portfólio
                  </a>
                </li> */}
                <li>
                  <a data-scroll href="#contato">
                    Contato
                  </a>
                </li>
              </Nav>
            </Menu>
          </div>
          <div>
            <Menu justifyContent="flex-end">
              <Nav white>
                <li>
                  <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
                </li>
                <li>
                  <a data-scroll href="#blog">
                    Blog
                  </a>
                </li>
                <li>
                  <button
                    onClick={() => window.open('https://ofertas.miquelao.com.br/')
                    }
                    style={{ border: 'none' }}
                    className="bg__button"
                  >
                    Compre um apê miquelão
                    {' '}
                    <img src={ArrowRight} alt="Seta" />
                  </button>
                </li>
              </Nav>
            </Menu>
          </div>
        </div>

        <Social>
          <a
            href="https://www.fb.com/construtoramiquelao"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FacebookIcon} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/construtoramiquelao/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={InstagramIcon} alt="Instagram" />
          </a>
        </Social>
        <BannerDots id="banner-dots">
          {!!data
            && data.acf.banners.map((banner, index) => (
              <div
                onClick={() => {
                  this.setState({ bannerPosition: index });
                  this.flkty.selectCell(index);
                }}
                className={bannerPosition === index ? 'active' : null}
              >
                {index + 1}
              </div>
            ))}
        </BannerDots>

        <div id="flickity" style={{ minHeight: loading ? '100vh' : 'unset' }}>
          {!!data && !loading ? (
            data.acf.banners.map(banner => (
              <div className="carousel-cell">
                <Header>
                  <div className="esq">
                    <BannerMenor>
                      <div>
                        <div className="titulo">
                          <span className="categoria">{banner.subtitulo}</span>
                          <span className="nome">
                            {banner.titulo.split(' ')[0]}
                            {' '}
                            <br />
                            {banner.titulo.split(' ')[1]}
                          </span>
                          {banner.link
                            ? (
                              <Link
                                to={`/empreendimentos/${banner.link.post_name}`}
                              >
                                Conheça
                                {' '}
                                <img src={ArrowRight} alt="Seta" />
                              </Link>
                            )
                            : (
                              <a target="_blank" rel="noreferrer" href={banner.link_alternativo}>
                                Conheça
                                {' '}
                                <img src={ArrowRight} alt="Seta" />
                              </a>
                            )
                          }
                        </div>
                        <div className="imagem">
                          <img src={banner.imagem_menor.url} alt="banner" />
                        </div>
                      </div>
                    </BannerMenor>
                  </div>
                  <div className="dir">
                    <BannerMaior>
                      <div>
                        <img src={banner.imagem_maior.url} alt="Banner" />
                      </div>
                    </BannerMaior>
                  </div>
                </Header>
              </div>
            ))
          ) : (
            <div style={{ width: '700px' }} />
          )}
        </div>

        <Categorias id="empreendimentos">
          <div
            onClick={() => {
              this.setState({ categoria: 'lancamento' });
              const scroll = new SmoothScroll();
              const anchor = document.querySelector('#empreendimentos');
              const reset = document.querySelector('#flickity');
              scroll.animateScroll(reset);
              scroll.cancelScroll();
              setTimeout(() => {
                scroll.animateScroll(anchor);
                scroll.destroy();
              }, 100);
            }}
            className={categoria === 'lancamento' ? 'active' : ''}
          >
            <div>
              <span>
                Lança
                <br />
                mentos
              </span>
              <span>
                clique aqui
                {' '}
                <img
                  src={
                    categoria === 'lancamento' ? ArrowRight : ArrowRightBlack
                  }
                  alt="Seta"
                />
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              this.setState({ categoria: 'na_planta' });
              const scroll = new SmoothScroll();
              const anchor = document.querySelector('#empreendimentos');
              const reset = document.querySelector('#flickity');
              scroll.animateScroll(reset);
              scroll.cancelScroll();
              setTimeout(() => {
                scroll.animateScroll(anchor);
                scroll.destroy();
              }, 100);
            }}
            className={categoria === 'na_planta' ? 'active' : ''}
          >
            <div>
              <span>
                Na
                <br />
                planta
              </span>
              <span>
                clique aqui
                {' '}
                <img
                  src={categoria === 'na_planta' ? ArrowRight : ArrowRightBlack}
                  alt="Seta"
                />
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              this.setState({ categoria: 'pronto_morar' });
              const scroll = new SmoothScroll();
              const anchor = document.querySelector('#empreendimentos');
              const reset = document.querySelector('#flickity');
              scroll.animateScroll(reset);
              scroll.cancelScroll();
              setTimeout(() => {
                scroll.animateScroll(anchor);
                scroll.destroy();
              }, 100);
            }}
            className={categoria === 'pronto_morar' ? 'active' : ''}
          >
            <div>
              <span>
                Prontos
                <br />
                para morar
              </span>
              <span>
                clique aqui
                {' '}
                <img
                  src={
                    categoria === 'pronto_morar' ? ArrowRight : ArrowRightBlack
                  }
                  alt="Seta"
                />
              </span>
            </div>
          </div>
        </Categorias>
        <Empreendimentos>
          {!!empreendimentos
            && empreendimentos.map((empreendimento, i) => {
              if (categoria === empreendimento.acf.tipo.value) {
                showMessage = false;
                return (
                  <Empreendimento
                    key={empreendimento.id}
                    backgroundimage={empreendimento.acf.banner.home.sizes.large}
                  >
                    <div className="empreendimento__content">
                      <h3>{empreendimento.title.rendered}</h3>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: empreendimento.acf.caracteristicas.texto,
                        }}
                      />
                      <Link to={`/empreendimentos/${empreendimento.slug}`}>
                        Conheça
                        <img src={ArrowRight} alt="Seta" />
                      </Link>
                    </div>
                  </Empreendimento>
                );
              }
              if (showMessage && empreendimentos.length - 1 === i) {
                return (
                  <div className="empreendimentos__resultado">
                    <span>Em breve novos lançamentos</span>
                  </div>
                );
              }
            })}
        </Empreendimentos>

        <Mapa>
          <h3 id="portifolio">
            Onde tem apê Miquelão,
            {' '}
            <span>tem qualidade</span>
          </h3>

          {/* Mapa */}
          <div>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCI_v75h1fLfSS3bBWxEHmiIUY8xFPfzQY',
              }}
              defaultCenter={{ lat: -25.0915892, lng: -50.1652471 }}
              defaultZoom={15}
              center={!!mapInfo && { ...mapInfo.mapa }}
            >
              {!!data
                && data.acf.portfolio.map(marker => (
                  <img
                    key={Math.random()}
                    src={MarkerIcon}
                    alt={marker.titulo}
                    style={{ height: '50px', cursor: 'pointer' }}
                    lat={marker.mapa.lat}
                    lng={marker.mapa.lng}
                    text={marker.titulo}
                    onClick={() => {
                      this.setState({ mapInfo: marker });
                    }}
                  />
                ))}
            </GoogleMapReact>
          </div>

          {!!mapInfo && (
            <div className="marker__infos">
              <div
                onClick={() => {
                  this.setState({ mapInfo: null });
                }}
              >
                Fechar
              </div>
              <img src={mapInfo.foto.url} alt={mapInfo.titulo} />
              <h2>{mapInfo.titulo}</h2>
              <span>{mapInfo.mapa.address}</span>
            </div>
          )}
        </Mapa>
        <Blog id="blog">
          <h3>Blog</h3>
          <Posts id="horizontal-scroll-posts">
            {!!posts
              && posts.map(post => (
                <Post
                  key={post.id}
                  className="post-class"
                  to={`/blog/${post.slug}`}
                >
                  {!!post._embedded['wp:featuredmedia'] ? (
                    <img
                      src={post._embedded['wp:featuredmedia'][0].source_url}
                      alt={post._embedded['wp:featuredmedia'][0].slug}
                    />
                  ) : (
                    <img
                      src="https://via.placeholder.com/340x190?text=Sem+Imagem"
                      alt="Sem Imagem"
                    />
                  )}

                  <h4>{post.title.rendered}</h4>
                  <span
                    dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                  />
                  <Link to={`/blog/${post.id}`} className="bg">
                    Leia mais
                    {' '}
                    <img src={ArrowRight} alt="Seta" />
                  </Link>
                </Post>
              ))}
          </Posts>

          <div className="horizontal__slider">
            <img src={SlideIcon} alt="Slide" />
            <span>Deslize para navegar</span>
            <Slider
              value={scrollPosition}
              max={1000}
              onChange={this.syncWithScroll}
              railStyle={{
                border: 'none',
                backgroundColor: '#4a4a4a',
                height: '2px',
              }}
              trackStyle={{ border: 'none', backgroundColor: '#6ABD45' }}
              handleStyle={{
                marginLeft: '-30px',
                marginTop: '-6px',
                width: '60px',
                border: 'solid 1px #6ABD45',
                backgroundColor: '#6ABD45',
                borderRadius: '0',
                boxShadow: 'none',
              }}
            />
          </div>

          <div className="all__posts">
            <Link to="/blog/" className="bg">
              Ver todos
              {' '}
              <img src={ArrowRight} alt="Seta" />
            </Link>
          </div>
        </Blog>

        <CallToAction>
          <div className="calltoaction__left">
            <h2>
              Se apaixonou
              {' '}
              <br />
              por algum dos nossos empreendimentos?
            </h2>
            <span>Você pode morar em um deles!</span>
            <a href="#contato">
              Clique aqui
              {' '}
              <img src={ArrowRight} alt="Seta" />
            </a>
          </div>
          <div className="calltoaction__image">
            <img src={CallToActionImage} alt="Call to action" />
          </div>
        </CallToAction>
        <Contato />
        <Footer />
      </Container>
    );
  }
}

export default Home;
