import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ScrollToTop from '../components/ScrollToTop';

import Home from '../pages/home';
import About from '../pages/about';
import Empreendimentos from '../pages/empreendimentos';
import Blog from '../pages/blog';
import Post from '../pages/blog/post';
import TrabalheConosco from '../pages/trabalheConosco';
import Redirecionar from '../components/Redirecionar';

const Routes = () => (
  <ScrollToTop>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/sobre" component={About} />
      <Route path="/empreendimentos/:slug" component={Empreendimentos} />
      <Route path="/blog/:slug" component={Post} />
      <Route path="/blog" component={Blog} />
      <Route path="/trabalhe-conosco" component={TrabalheConosco} />
      <Route
        path="/palazzoducale"
        render={props => (
          <Redirecionar
            {...props}
            path="http://palazzoducale.miquelao.com.br"
          />
        )}
      />
      <Route
        path="/palazzoferrara"
        render={props => (
          <Redirecionar
            {...props}
            path="http://palazzoferrara.miquelao.com.br"
          />
        )}
      />
      <Route
        path="/benevento"
        render={props => (
          <Redirecionar {...props} path="http://benevento.miquelao.com.br" />
        )}
      />
      <Route
        path="/goldenweeks"
        render={props => (
          <Redirecionar
            {...props}
            path="http://material.miquelao.com.br/golden-weeks"
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  </ScrollToTop>
);

export default Routes;
