import styled from "styled-components";

import { Link } from "react-router-dom";

export const Container = styled.div`
  .mobileNav {
    display: none;
    border-bottom: 2px solid #6abd45;
    position: fixed;
    z-index: 100;
    background-color: #fff;
    width: 100%;
    padding: 5px 20px;
    height: fit-content;
    top: 0px;

    @media (max-width: 480px) {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      position: relative;
      width: 55px;
    }

    #mobile-menu {
      width: 100%;
      height: 100vh;
      top: -101vh;
      background-color: #fff;
      transition: all ease-out 0.5s;
      position: absolute;
      left: 0px;

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35%;
        list-style: none;

        li {
          margin-top: 30px;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;

          a {
            color: #4a4a4a;
          }

          .bg__button {
            position: relative;
            line-height: 16px;
            text-transform: uppercase;
            color: #fff;

            width: 220px;
            height: 30px;
            background: #6abd45;
            display: flex;
            text-align: right;
            align-items: center;
            justify-content: flex-end;
            margin-left: 30px;
            transition: all ease-in 0.2s;

            img {
              height: 8px;
              padding: unset;
              margin: 0 10px;
            }

            &:before {
              content: "";
              position: absolute;
              left: -30px;
              width: 0px;
              height: 0px;
              border-left: 30px solid transparent;
              border-bottom: 30px solid #6abd45;
              transition: all ease-in 0.2s;
            }

            &:hover {
              background: #84d660;

              &:before {
                border-bottom-color: #84d660;
              }

              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .active {
      top: 100% !important;
    }

    /* div {
      height: 100vh;
      width: 100%;
      background: #fff;
      top: -100vh;
      transition: all ease-out 0.5s;
      position: absolute;
      display: none;
    }

    
    } */

    button {
      position: relative;
    }
  }

  #header.sticky {
    position: fixed;
    background-color: #fff;
    border-bottom: 2px solid #6abd45;
    transition: all ease-in 0.3s;
    z-index: 100;

    > div > div {
      margin-top: 0px;
      margin-bottom: 0px;

      > a {
        img {
          height: 65px;
        }
      }
    }

    a {
      color: #4a4a4a;
    }
  }

  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 99;
  }

  .flickity-viewport {
    height: 846.25px !important;
  }

  .carousel-cell {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 480px) {
    .nav {
      display: none;
    }

    #flickity {
      min-height: calc(100vh - 120px);
      padding-top: 90px;
    }

    .flickity-viewport {
      height: 543.25px !important;
    }
  }
`;

export const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    margin-top: -60px;

    .dir {
      display: none;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  margin: 30px 50px;
  height: 85px;

  img {
    margin-left: 50px;
    height: 80px;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export const Nav = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;

  li {
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    list-style-type: none;

    a,
    button {
      position: relative;
      padding: 0 15px;
      color: ${props => (props.white ? "#fff" : "#4a4a4a")};
      text-transform: uppercase;
      transition: all ease-in 0.2s;

      &:after {
        content: "";
        background: transparent;
        transition: all ease-in 0.2s;
      }

      &:hover {
        &:after {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 1px;
          background: ${props => (props.white ? "#fff" : "#4a4a4a")};
        }
      }
    }

    .bg__button {
      position: relative;
      line-height: 16px;
      text-transform: uppercase;
      color: #fff;

      width: 220px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin-left: 30px;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: unset;
        margin-left: 10px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: #84d660;

        &:before {
          border-bottom-color: #84d660;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const BannerMenor = styled.div`
  margin-top: 240px;

  .titulo {
    display: flex;
    flex-direction: column;
    margin-left: 25%;

    .categoria {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 19.3px;
      text-transform: uppercase;
      color: #4a4a4a;
    }

    .nome {
      font-family: "Noirden";
      font-size: 80px;
      font-weight: 100;
      line-height: 81px;
      letter-spacing: 10.9px;
      text-transform: uppercase;
      color: #4a4a4a;
    }

    a {
      position: relative;
      align-self: center;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;

      width: 190px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin-top: 40px;
      margin-bottom: -15px;
      z-index: 99;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: 0 10px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: #84d660;

        &:before {
          border-bottom-color: #84d660;
        }
      }
    }
  }

  .imagem {
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      padding-top: 50px;
      width: 90%;
      object-fit: cover;
    }
  }

  @media (max-width: 480px) {
    margin-top: 100px;

    .titulo {
      margin: unset;

      .categoria {
        margin: 0 auto;
        padding: unset;
        text-align: center;
      }

      .nome {
        font-size: 60px;
        margin: 0 auto;
        text-align: center;

        br {
          display: none;
        }
      }
    }
  }
`;

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: calc(3% - 5px);
  top: 35%;
  height: 50px;
  z-index: 9999;

  a {
    img {
      height: 18px;
    }
  }
`;

export const BannerDots = styled.div`
  position: absolute;
  right: 3%;
  top: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 999999;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
  }

  .active {
    font-weight: 900;
  }
`;

export const BannerMaior = styled.div`
  position: absolute;
  top: 0;
  width: 50%;
  z-index: -1;

  div {
    height: 846px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const Categorias = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 45px 3% 25px 3%;

  background: #f3f3f3;

  .active {
    position: relative;
    background: #6abd45;

    span {
      color: #fff;

      &:after {
        background: #fff !important;
      }
    }

    &:after {
      content: "";
      position: absolute;
      background: #6abd45;
      height: 45px;
      width: 100%;
      bottom: -45px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 180px;
    background: #fff;
    margin-left: 15px;
    cursor: pointer;
    transition: all ease-in 0.2s;

    div {
      display: flex;
      align-items: flex-end;

      span {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        color: #4a4a4a;
        display: flex;
        align-items: center;

        img {
          margin: 0 15px;
          height: 8px;
        }

        &:first-child {
          font-family: "Noirden";
          font-size: 32px;
          font-weight: 300;
          text-transform: unset;
          height: 79px;
          margin-right: 45px;
        }

        &:last-child {
          &:after {
            content: "";
            position: absolute;
            right: 40px;
            top: 85px;
            height: 100px;
            width: 1px;
            background: #000;
            transform: rotate(40deg);
          }
        }
      }
    }

    &:after {
      content: "";
      background: transparent;
      transition: all ease-in 0.2s;
    }

    &:hover {
      transform: scale(1.02);
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;

    .active {
      &:after {
        display: none;
      }
    }

    > div {
      margin: unset;

      &:hover {
        transform: unset;
      }

      div {
        span {
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const Empreendimentos = styled.section`
  display: grid;
  background: #f3f3f3;

  .empreendimentos__resultado {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    font-size: 20px;
    font-weight: 300;
    color: #4a4a4a;
  }
`;

export const Empreendimento = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: fit-content;
  margin-top: 30px;

  .empreendimento__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    background-image: url(${props => props.backgroundimage});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-left: 100px;

    h3 {
      font-family: "Noirden";
      font-size: 73px;
      font-weight: 100;
      color: #fff;
      margin-bottom: 40px;
    }

    span {
      width: 40%;
      font-size: 15px;
      color: #fff;
    }

    a {
      position: relative;
      margin-left: 30%;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;

      width: 190px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;
      z-index: 99;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: 0 10px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
        transition: all ease-in 0.2s;
      }

      &:after {
        content: "";
        position: absolute;
        height: 200px;
        width: 1px;
        background: #fff;
        transform: rotate(40deg);
        right: -60px;
        top: -100px;
      }

      &:hover {
        background: #84d660;

        &:before {
          border-bottom-color: #84d660;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .empreendimento__content {
      padding: unset;
      padding-bottom: 30px;

      h3 {
        font-size: 60px;
        text-align: center;
      }

      span {
        width: 80%;
        text-align: center;
        margin: 0 auto;
      }

      a {
        &:after {
          display: none;
        }
      }
    }
  }
`;

export const Mapa = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: "Noirden";
    font-size: 45px;
    font-weight: 200;
    color: #4a4a4a;
    margin-top: 130px;

    span {
      font-weight: normal;
      color: #6abd45;
    }
  }

  > div:first-of-type {
    height: 500px;
    width: 100%;
    margin-top: 50px;
  }

  .marker__infos {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 255px;
    left: 10px;
    height: 390px;
    width: 380px;
    padding: 0 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 3px 3px 7px 1px rgba(74, 74, 74, 0.15);
    z-index: 999;

    div {
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 13px;
      color: #4a4a4a;
      cursor: pointer;
    }

    img {
      height: 230px;
      object-fit: cover;
      margin-top: 30px;
    }

    h2 {
      font-size: 25px;
      text-align: center;
      color: #4a4a4a;
      margin-top: 15px;
    }

    span {
      font-size: 16px;
      text-align: center;
      line-height: 20px;
      color: #4a4a4a;
      margin-top: 10px;
    }
  }

  @media (max-width: 480px) {
    h3 {
      text-align: center;
      margin-top: 60px;
    }

    .marker__infos {
      display: none;
    }
  }
`;

export const Blog = styled.section`
  display: flex;
  flex-direction: column;

  width: 80%;
  max-width: 1240px;
  margin: 145px auto 0;

  h3 {
    font-family: "Noirden";
    font-size: 45px;
    font-weight: 200;
    color: #4a4a4a;
  }

  .all__posts {
    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
      position: relative;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #fff;
      border: none;
      align-self: center;

      width: 110px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin: 20px auto 0 auto;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: unset;
        margin: 0 10px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: #84d660;

        &:before {
          border-bottom-color: #84d660;
        }
      }
    }
  }

  .horizontal__slider {
    display: grid;
    grid-template-columns: 50px 200px 1fr;
    align-items: center;
    margin-top: 80px;

    span {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: #4a4a4a;
      padding-right: 25px;
    }
  }

  @media (max-width: 480px) {
    max-width: 300px;
    margin: 60px auto 0;

    .horizontal__slider {
      grid-template-columns: 50px 1fr;
      margin: unset;

      span {
        text-align: center;
        padding: unset;
      }

      > div {
        grid-column-end: span 2;
        margin-top: 35px;
        padding-left: 15px;
      }
    }
  }
`;

export const Posts = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 35px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Post = styled(Link)`
  flex: 0 0 auto;
  width: 340px;
  margin-right: 80px;
  margin-bottom: 30px;

  > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: all ease-in 0.2s;
  }

  h4 {
    font-size: 26px;
    font-weight: bold;
    color: #4a4a4a;
    margin: 45px 0 35px 0;
  }

  span {
    font-size: 15px;
    color: #4a4a4a;
  }

  .bg {
    position: relative;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #fff;
    border: none;
    align-self: center;
    opacity: 0;

    width: 110px;
    height: 30px;
    background: #6abd45;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    margin: 20px auto 0 auto;
    transition: all ease-in 0.2s;

    img {
      height: 8px;
      padding: unset;
      margin: 0 10px;
    }

    &:before {
      content: "";
      position: absolute;
      left: -30px;
      width: 0px;
      height: 0px;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #6abd45;
      transition: all ease-in 0.2s;
    }

    &:hover {
      background: #84d660;

      &:before {
        border-bottom-color: #84d660;
      }
    }
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    .bg {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const CallToAction = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 450px;
  margin-top: 150px;

  .calltoaction__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
    margin: auto 0;
    background: #33499c;
    color: #fff;
    padding-left: 20%;

    h2 {
      font-family: "Noirden";
      font-size: 40px;
      width: 65%;
    }

    span {
      margin-top: 32px;
      font-size: 21px;
    }

    a {
      position: relative;
      margin-left: 30px;
      margin-top: 35px;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;

      width: 220px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      z-index: 99;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: 0 10px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: #84d660;

        &:before {
          border-bottom-color: #84d660;
        }
      }
    }
  }

  .calltoaction__image {
    height: 450px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;

    .calltoaction__left {
      padding: unset;

      h2 {
        width: 80%;
        margin: 0 auto;
        font-size: 30px;
        text-align: center;
      }

      span {
        font-size: 18px;
        text-align: center;
      }

      a {
        align-self: center;
      }
    }

    .calltoaction__image {
      display: none;
    }
  }
`;
