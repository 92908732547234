import styled from "styled-components";

import { Link } from "react-router-dom";

export const Container = styled.div`
  padding-top: 120px;
`;

export const Banner = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;
  height: 730px;
  background-image: url(${props => props.image});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;

  h2 {
    font-family: "Noirden";
    font-size: 56px;
    font-weight: normal;
    width: 500px;
    color: #fff;
  }

  span {
    font-size: 15px;
    font-weight: normal;
    color: #fff;
    width: 360px;
    margin-top: 45px;
  }

  a {
    position: relative;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #fff;
    border: none;

    width: 110px;
    height: 30px;
    background: #6abd45;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    margin-left: 30px;
    margin-top: 20px;
    transition: all ease-in 0.2s;

    img {
      height: 8px;
      padding: unset;
      margin: 0 10px;
    }

    &:before {
      content: "";
      position: absolute;
      left: -30px;
      width: 0px;
      height: 0px;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #6abd45;
      transition: all ease-in 0.2s;
    }

    &:hover {
      background: #84d660;

      &:before {
        border-bottom-color: #84d660;
      }
    }
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
    text-align: center;
    padding: 0 10%;

    h2 {
      width: unset;
      font-size: 30px;
    }

    span {
      width: unset;
    }

    a {
      align-self: center;
    }
  }

  @media (max-width: 320px) {
  }
`;

export const Recentes = styled.section`
  width: 80%;
  max-width: 1240px;
  margin: auto;

  h2 {
    font-family: "Noirden";
    font-size: 45px;
    font-weight: normal;
    color: #6abd45;
    margin-top: 75px;
  }

  @media (max-width: 480px) {
    text-align: center;

    h2 {
      margin-top: 40px;
    }
  }
`;

export const Posts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 110px;
  margin-top: 75px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-gap: 65px;
  }
`;

export const Post = styled(Link)`
  display: flex;
  flex-direction: column;

  > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: all ease-in 0.2s;
  }

  h3 {
    font-size: 26px;
    font-weight: bold;
    color: #4a4a4a;
    margin-top: 45px;
  }

  span {
    font-size: 15px;
    font-weight: normal;
    color: #4a4a4a;
    margin-top: 30px;
  }

  .bg {
    position: relative;
    line-height: 16px;
    text-transform: uppercase;
    color: #fff;
    border: none;
    align-self: center;
    opacity: 0;

    width: 110px;
    height: 30px;
    background: #6abd45;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    transition: all ease-in 0.2s;

    img {
      height: 8px;
      padding: unset;
      margin: 0 10px;
    }

    &:before {
      content: "";
      position: absolute;
      left: -30px;
      width: 0px;
      height: 0px;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #6abd45;
      transition: all ease-in 0.2s;
    }

    &:hover {
      background: #84d660;

      &:before {
        border-bottom-color: #84d660;
      }
    }
  }

  &:hover {
    img {
      position: relative;
      transform: scale(1.05);
    }

    .bg {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    h3 {
      text-align: center;
    }

    span {
      text-align: left;
    }

    .bg {
      opacity: 1;
    }
  }
`;

export const Antigas = styled.section`
  width: 80%;
  max-width: 1240px;
  margin: 140px auto 0 auto;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-family: "Noirden";
      font-size: 45px;
      font-weight: normal;
      color: #6abd45;
    }

    > div {
      display: flex;
      align-items: center;

      .bg {
        position: relative;
        line-height: 16px;
        text-transform: uppercase;
        color: #fff;
        border: none;

        width: 110px;
        height: 30px;
        background: #6abd45;
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: flex-end;
        margin-left: 50px;
        margin-bottom: -17px;
        transition: all ease-in 0.2s;

        img {
          height: 8px;
          padding: unset;
          margin: 0 10px;
        }

        &:before {
          content: "";
          position: absolute;
          left: -30px;
          width: 0px;
          height: 0px;
          border-left: 30px solid transparent;
          border-bottom: 30px solid #6abd45;
          transition: all ease-in 0.2s;
        }

        &:hover {
          background: #84d660;

          &:before {
            border-bottom-color: #84d660;
          }
        }
      }
    }
  }

  .old__posts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 115px;
    grid-row-gap: 75px;
    position: relative;
    margin-top: 100px;

    a {
      font-size: 26px;
      font-weight: bold;
      color: #33499c;
    }

    &:after {
      content: "";
      position: absolute;
      left: 30.5%;
      height: 100%;
      width: 1px;
      background: #000;
    }

    &:before {
      content: "";
      position: absolute;
      right: 32%;
      height: 100%;
      width: 1px;
      background: #000;
    }
  }

  .rc-pagination-item:hover {
    border-color: #84d660;

    a {
      color: #84d660;
    }
  }

  .rc-pagination-item-active {
    background-color: #84d660;
    border-color: #84d660;
  }

  @media (max-width: 480px) {
    > div {
      flex-direction: column;
      align-items: flex-start;

      h2 {
        text-align: center;
      }

      > div {
        flex-direction: column;
        margin-top: 30px;
        align-self: center;

        .bg {
          margin: unset;
          margin-left: 30px;
          margin-top: 20px;
        }
      }
    }

    .old__posts {
      grid-template-columns: 1fr;

      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
  }
`;
