import styled from "styled-components";

export const Container = styled.div`
  padding-top: 120px;

  #share {
    display: none;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-image: url(${props => props.bgImage});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  h2 {
    font-family: "Noirden";
    font-size: 45px;
    font-weight: 300;
    color: #fff;
  }

  @media (max-width: 480px) {
    h2 {
      padding: 0 10%;
      text-align: center;
    }
  }
`;

export const Empreendimento = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  .infos {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-height: 400px;
    padding-left: 20%;

    > img {
      width: 70%;
      margin: auto;
      object-fit: contain;
      margin-top: 70px;
    }

    .descricao {
      font-size: 15px;
      font-weight: normal;
      line-height: 20px;
      color: #4a4a4a;
      margin-top: 50px;
    }

    .localizacao {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 800;
      color: #4a4a4a;
      margin-top: 50px;
      text-transform: uppercase;

      img {
        height: 30px;
        margin-right: 15px;
      }
    }

    .obras {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-top: 50px;

      .item {
        display: flex;
        flex-direction: column;
        position: relative;

        span {
          font-size: 15px;
          font-weight: normal;
          color: #4a4a4a;
          text-transform: uppercase;
        }

        .porcentagem {
          align-self: center;
          justify-self: center;
          margin-top: 10px;
          z-index: 8;
        }

        div {
          position: absolute;
          top: 24px;
          height: 25px;
          min-width: 1%;
          background: #6abd45;
          font-size: 12px;
          font-weight: normal;
          color: #4a4a4a;
          z-index: 1;
        }
      }
    }

    .previsao {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 80px;
      width: 100%;
      border: 1px solid #979797;
      margin-top: 20px;

      > span {
        font-size: 18px;
        font-weight: normal;
        color: #4a4a4a;
        text-transform: uppercase;
        letter-spacing: 2.5px;

        span {
          font-weight: 800;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: -100px;
        top: 60px;
        height: 150px;
        width: 1px;
        background: #4a4a4a;
        transform: rotate(45deg);
      }
    }

    a {
      align-self: flex-end;
      position: relative;
      line-height: 16px;
      text-transform: uppercase;
      color: #fff;

      width: 200px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin-left: 30px;
      margin-top: 20px;
      transition: all ease-in 0.2s;

      img {
        height: 8px;
        padding: unset;
        margin: 0 10px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: #84d660;

        &:before {
          border-bottom-color: #84d660;
        }
      }
    }
  }

  .imagem {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: -50px;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;

    .infos {
      padding: unset;
      margin: auto;

      .obras {
        grid-template-columns: 1fr;
      }

      .previsao {
        text-align: center;
      }

      a {
        align-self: center;
      }
    }

    .imagem {
      display: none;
    }
  }
`;

export const Descricao = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 460px;
  width: 80%;
  max-width: 1240px;
  margin: auto;
  margin-top: 65px;

  @media (max-width: 480px) {
    height: fit-content;
    padding-bottom: 50px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      background: #6abd45;

      h3 {
        font-family: "Noirden";
        font-size: 70px;
        font-weight: 100;
        color: #fff;
        width: 80%;
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;

        @media (max-width: 1440px) {
          font-size: 60px;
        }

        @media (max-width: 1215px) {
          font-size: 40px;
        }
      }
    }

    &:last-child {
      flex-direction: column;
      align-items: flex-end;

      span {
        width: 85%;
        color: #4a4a4a;

        &:first-child {
          font-size: 15px;
          font-weight: normal;
          line-height: 20px;

          p {
            margin-top: 10px;
          }
        }

        &:last-child {
          font-family: "Noirden";
          font-size: 30px;
          font-weight: 300;
          color: #6abd45;
          margin-top: 55px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    width: 100%;

    div {
      &:first-child {
        height: fit-content;
        padding: 30px 20px;

        h3 {
          width: 100%;
          font-size: 35px;
          text-align: center;
        }
      }

      &:last-child {
        span {
          width: 100%;
          padding: 0 5%;

          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
`;

export const Plantas = styled.div`
  height: 970px;
  width: 100%;
  background: #f5f5f5;

  h3 {
    font-family: "Noirden";
    font-size: 45px;
    font-weight: normal;
    color: #4a4a4a;
  }

  .flickity-viewport {
    height: 100% !important;
  }

  .flickity-button {
    background: transparent;
    color: #4a4a4a;

    &:hover {
      background: transparent;
      color: #6abd45;
    }
  }

  .carousel-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 10%;
  }

  @media (max-width: 480px) {
    height: 1100px;

    .carousel-cell {
      padding: unset;
      justify-content: unset;

      h3 {
        text-align: center;
        margin-top: 35px;
      }
    }
  }
`;

export const Planta = styled.div`
  display: flex;

  margin-top: 35px;
  height: 600px;

  > div {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;

      background: #fff;
      width: 50%;
      border: 1px solid #979797;

      img {
        width: 450px;
        height: 450px;
        object-fit: contain;
      }
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 50%;

      h4 {
        font-size: 45px;
        font-weight: normal;
        color: #4d4d4d;
        text-transform: uppercase;

        span {
          font-weight: 900;
          color: #6abd45;
        }

        &:last-child {
          display: flex;
          align-items: center;
          font-size: 15px;

          span {
            margin-right: 20px;
            font-size: 70px;
            font-weight: bold;
            text-transform: none;
          }
        }
      }

      > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 95px;
          width: 95px;
          background: #fff;

          @media (max-width: 480px) {
            height: 130px;
            width: 130px;
          }

          span {
            font-size: 12px;
            font-weight: normal;
            color: #4a4a4a;
            text-transform: uppercase;
            text-align: center;
            width: 110px;
            word-break: break-word;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    height: unset;

    > div {
      &:first-child {
        width: 90%;
        margin: auto;

        a {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 90%;
            height: 90%;
          }
        }
      }

      &:last-child {
        width: 100%;
        margin-top: 40px;

        h4 {
          &:last-child {
            flex-direction: column;
            margin: 25px 0;

            span {
              margin: unset;
              font-size: 59px;
            }
          }
        }

        > div {
          grid-template-columns: 1fr 1fr;
          margin-top: 20px;
        }
      }
    }
  }
`;

export const Galeria = styled.section`
  display: flex;
  flex-direction: column;

  width: 80%;
  max-width: 1240px;
  margin: auto;
  margin-bottom: 100px;

  h3 {
    font-family: "Noirden";
    font-size: 45px;
    font-weight: normal;
    color: #4a4a4a;
    margin: 70px 0;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 150px;
    grid-auto-flow: row dense;
    grid-gap: 10px;

    a {
      position: relative;
      grid-row-end: span 3;
      box-shadow: -2px 2px 10px 0px rgba(68, 68, 68, 0.4);
      transition: transform 0.3s ease-in-out;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.2;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover {
        transform: scale(1.015);

        &:after {
          opacity: 0;
        }
      }
    }

    .image__small {
      grid-row-end: span 2;
    }
  }

  button {
    align-self: center;
    position: relative;
    margin-left: 30px;
    margin-top: 75px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    border: none;

    width: 220px;
    height: 30px;
    background: #6abd45;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    transition: all ease-in 0.2s;

    img {
      height: 8px;
      padding: 0 10px;
    }

    &:before {
      content: "";
      position: absolute;
      left: -30px;
      width: 0px;
      height: 0px;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #6abd45;
      transition: all ease-in 0.2s;
    }

    &:hover {
      background: #84d660;

      &:before {
        border-bottom-color: #84d660;
      }
    }
  }

  @media (max-width: 480px) {
    width: 90%;

    h3 {
      text-align: center;
    }

    > div {
      grid-template-columns: 1fr;
      grid-auto-rows: unset;
      grid-auto-flow: unset;

      a {
        grid-row-end: unset;
      }

      .image__small {
        grid-row-end: unset;
      }
    }
  }
`;

export const Mapa = styled.section`
  height: 490px;
  width: 100%;

  .pin__icon {
    width: 45px;
    margin-top: -40px;
    margin-left: -15px;
  }
`;
